import * as React from 'react';
import { Text, HStack, Box, Button, VStack, Pressable, CloseIcon } from 'native-base';
import LogoSvg from '../../assets/images/Actavivo-Logo.svg';
import 'react-native-gesture-handler';
import { Auth } from 'Platform/Auth';
import { Platform } from 'react-native';
import Unsubscribe from '../Unsubscribe/Unsubscribe';
import * as AV from '../../AVCore/AVCore';
import { useDispatch } from 'react-redux';
import { setEntityToken } from '../../Redux/Actions/userActions';
import { setuserisguardian } from '../../Redux/Actions/userActions';
import { Loc } from '../../App/Loc';
import settings from '../../Platform/settings.json';
import { isMobile as isMobileDevice } from 'react-device-detect';
import { Dimensions } from 'react-native';

function Signin () {
    const [ entityLink, setentityLink ] = React.useState<any>('');
    const [ isShowfollow, setisShowfollow ] = React.useState(true);
    const [ isLandingPage, setIsLandingPage ] = React.useState(false);
    const [ txtWelcome, setTxtWelcome ] = React.useState<string | undefined>();
    const [ txtDescription, setTxtDescription ] = React.useState<string | undefined>();
    const [ txtTermofuse, setTxtTermofuse ] = React.useState<string | undefined>();
    const [ txtWhyActavivo, setTxtWhyactavivo ] = React.useState<string | undefined>();
    const [ txtSign, setTxtSignin ] = React.useState<string | undefined>();
    const [ txtPrivacy, setTxtPrivacy ] = React.useState<string | undefined>();
    const [ txtApplestore, setTxtAppleStore ] = React.useState<string | undefined>();
    const [ txtGoogleStore, setTxtGoolgeStore ] = React.useState<string | undefined>();
    const [ txtInvite, setTxtInvite ] = React.useState<string | undefined>();
    const [ txtSocial, setTxtSocial ] = React.useState<string | undefined>();
    const [ txtNext, setTxtNext ] = React.useState<string | undefined>();
    const [ isPlayer, setIsPlayer ] = React.useState(false);
    const [ isOpen, setIsOpen ] = React.useState(true);
    const [ playername, setIsPlayerName ] = React.useState<string | undefined>();
    const [ parentguardianname, setIsParentGuardianname ] = React.useState<string | undefined>();
    const [ playerDescription, setIsplayerDescription ] = React.useState<string | undefined>();
    const [ isActivatePage, setIsActivatepage ] = React.useState(false);
    const [ isInviteName, setIsInviteName ] = React.useState<string | undefined>();
    const [ isNonPlayer, setIsNonPlayer ] = React.useState<string | undefined>();
    const dispatch = useDispatch();
    const [ textagreeandterms, settextagreeandterms ] = React.useState<string | undefined>();
    const [ appdescription, setappdescription ] = React.useState<string | undefined>();
    const [ openApp, setopenApp ] = React.useState<string | undefined>();
    const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.actavivo';
    const appStoreUrl = 'https://apps.apple.com/app/actavivo/id1611252168';
    const [ issigninLoading, setissigninLoading ] = React.useState(false);
    const dimensions = Dimensions.get( 'window' );
    const [ pathUrl, setPathUr ] = React.useState('actavivo://actavivo.com' );
    const isMobile = dimensions?.width <= 600 || false;
    const RenderUnsubscribe = React.memo(() => {
        return <Unsubscribe />;
    });

    const handleSignIn = (type) => {
        if (type === 'guardian') {
            dispatch(setuserisguardian(true));
        } else {
            dispatch(setuserisguardian(false));
        }
        Auth.signIn();

    };

    const redirectToApp = () =>
    {
        if ( isMobileDevice )
        {
            const isIOS = /iPad|iPhone|iPod/.test( navigator.userAgent );
            const androidAppUrl = pathUrl;
            const storeUrl = isIOS ? appStoreUrl : googlePlayUrl;

            if ( !isIOS )
            {
                try
                {
                    // Redirect to the Android app
                    window.location.href = androidAppUrl;

                    // If the app is not installed, after 2 seconds, redirect to the Google Play Store
                    setTimeout( function ()
                    {
                        window.location.href = storeUrl;
                    }, 2000 );
                } catch ( e )
                {
                    console.error( 'Error during redirection:', e );
                    window.location.href = storeUrl; // Fallback to Play Store if there's any error
                }
            } else
            {
                // For iOS, try opening the App Store directly
                window.location.href = androidAppUrl;
            }
        }
    };

    const RenderSignIn = React.memo(() => {
        return (
            <Box bgColor={ 'AVColor.primary' } width={ '100%' } height={ '100vh' }>
                { isMobileDevice && isOpen && (
                    <VStack
                        paddingY={ '10px' }
                        backgroundColor={ 'white' }
                        shadow={ 5 }
                        borderRadius={ '10px' }
                        width={ '100%' }
                        zIndex={ 999 }
                    >
                        <HStack marginLeft={ '10px' } space={ 3 }>
                            <Box position={ 'absolute' }>
                                <img src={ LogoSvg } height={ '45px' } width={ '45px' } />
                            </Box>
                            <Text
                                marginLeft={ '45px' }
                                width={ '55%' }
                                fontSize={ '14' }
                                fontWeight={ 'medium' }
                                numberOfLines={ 2 }
                            >
                                { appdescription
                                    ? appdescription
                                    : ' Actavivo:Download the mobile app for the best experience!' }
                            </Text>
                            <Button marginRight={ '10px' } bgColor={ 'AVColor.primary' } onPress={ redirectToApp }>
                                { openApp ? openApp : 'Open' }
                            </Button>
                            <Pressable
                                padding={ 1.5 }
                                rounded={ 'full' }
                                position={ 'absolute' }
                                right={ '3px' }
                                top={ -7 }
                                zIndex={ 999 }
                                _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                                backgroundColor={ 'AVColor.buttonBg' }
                                onPress={ () => setIsOpen(false) }
                            >
                                <CloseIcon size={ '10px' } color={ 'AVColor.secondaryText' } />
                            </Pressable>
                        </HStack>
                    </VStack>
                ) }
                <VStack flex={ 1 } space={ 5 } px={ 5 } alignItems={ 'center' } justifyContent={ 'center' }>
                    <img src={ LogoSvg } style={ { width: isMobile ? '180px' : '287px', height: isMobile ? '180px' : '287px', objectFit: 'cover' } } alt='' />
                    <Text variant={ 'AVLandingHeaderText' }>
                        { txtWelcome }
                    </Text>
                    { !isShowfollow && !isPlayer && !isActivatePage && (
                        <>
                            <Text variant={ 'AVLandingTitleText' }>
                                { txtDescription }
                            </Text>
                            <Text variant={ 'AVLandinglinetext' }>
                                { textagreeandterms }
                            </Text>
                            <HStack space={ 6 } justifyContent={ 'center' } width={ '100%' }>
                                <Button
                                    variant={ isMobile ? 'AVOutlineMobileButton' : 'AVOutlineButton' }
                                    onPress={ () => {
                                        window.open(settings?.whyactavivo);
                                    } }
                                >
                                    { txtWhyActavivo }
                                </Button>
                                <Button
                                    variant={ isMobile ? 'AVOutlineMobileButton' : 'AVOutlineButton' } isLoading={ issigninLoading }
                                    onPress={ () => {
                                        setissigninLoading(true); Auth.signIn();
                                    } }
                                >
                                    { txtSign }
                                </Button>
                            </HStack>
                            <HStack space={ 10 } justifyContent={ 'space-between' }>
                                <Pressable
                                    onPress={ () => {
                                        window.open(settings?.termsOfUse);
                                    } }
                                >
                                    <Text underline color={ 'AVColor.white' }>
                                        { txtTermofuse }
                                    </Text>
                                </Pressable>
                                <Pressable
                                    onPress={ () => {
                                        window.open(settings?.privacyPolicy);
                                    } }
                                >
                                    <Text underline color={ 'AVColor.white' }>
                                        { txtPrivacy }
                                    </Text>
                                </Pressable>
                            </HStack>
                            <HStack space={ 10 } justifyContent={ 'space-between' }>
                                { Platform.OS == 'ios' && (
                                    <Pressable
                                        onPress={ () => {
                                            window.open(settings?.appleStore);
                                        } }
                                    >
                                        <img
                                            src={ settings?.appstoreimage }
                                            style={ {
                                                width: 30,
                                                height: 30,
                                                objectFit: 'cover',
                                                position: 'absolute',
                                                top: '33px',
                                                left: '48px',
                                            } }
                                            alt=''
                                        />
                                        <Text underline color={ 'AVColor.white' }>
                                            { txtApplestore }
                                        </Text>
                                    </Pressable>
                                ) }
                                { Platform.OS == 'android' && (
                                    <Pressable
                                        onPress={ () => {
                                            window.open(settings?.playstore);
                                        } }
                                    >
                                        <img
                                            src={ settings?.playstoreimage }
                                            style={ {
                                                width: 30,
                                                height: 30,
                                                objectFit: 'cover',
                                                position: 'absolute',
                                                top: '33px',
                                                left: '48px',
                                            } }
                                            alt=''
                                        />
                                        <Text underline color={ 'AVColor.white' }>
                                            { txtGoogleStore }
                                        </Text>
                                    </Pressable>
                                ) }
                            </HStack>
                        </>
                    ) }
                    { isLandingPage && !isPlayer && !isActivatePage && (
                        <>
                            <Text variant={ 'AVLandingTitleText' }>
                                { txtInvite }
                                { entityLink?.title }
                            </Text>
                            <Text variant={ 'AVLandingTitleText' }>
                                { txtSocial }
                            </Text>
                            <HStack space={ 6 } justifyContent={ 'space-between' } height={'55px'} width={'90px'}>
                                <Button
                                    width={ '100%' }
                                    justifyContent={ 'center' }
                                    borderRadius={ '20px' }
                                    borderWidth={ '1px' }
                                    paddingX={ '24px' }
                                    borderColor={ 'white' }
                                    backgroundColor={ 'AVColor.primary' }
                                    onPress={ async () => { Auth.signIn() } }
                                >
                                    <Text color={ 'white' } fontWeight={ 500 } fontSize={ '16px' } textAlign={ 'center' } >
                                        { txtNext }
                                    </Text>
                                </Button>
                            </HStack>
                        </>
                    ) }
                    { isPlayer && !isActivatePage && (
                        <>
                            <Text variant={ 'AVLandingTitleText' }>
                                { playerDescription }
                            </Text>
                            <HStack space={ 6 } justifyContent={ 'center' } width={ '100%' }>
                                <Button
                                    variant={ isMobile ? 'AVOutlineMobileButton' : 'AVOutlineButton' }
                                    onPress={ () => {
                                        handleSignIn('player');
                                    } }
                                >
                                    { playername }
                                </Button>
                                <Button
                                    variant={ isMobile ? 'AVOutlineMobileButton' : 'AVOutlineButton' }
                                    onPress={ () => {
                                        handleSignIn('guardian');
                                    } }
                                >
                                    { parentguardianname }
                                </Button>
                            </HStack>
                        </>
                    ) }
                    { isActivatePage && !isPlayer && !isShowfollow && (
                        <>
                            <Text variant={ 'AVLandingTitleText' }>
                                { txtDescription }
                            </Text>
                            <Text variant={ 'AVLandingTitleText' }>
                                { isNonPlayer }
                            </Text>
                            <HStack space={ 6 } justifyContent={ 'center' } width={ '100%' }>
                                <Button
                                    variant={ isMobile ? 'AVOutlineMobileButton' : 'AVOutlineButton' }
                                    onPress={ () => {
                                        handleSignIn('player');
                                    } }
                                >
                                    { isInviteName }
                                </Button>
                            </HStack>
                        </>
                    ) }
                </VStack>
            </Box>
        );
    });

    React.useEffect(() => {
        async function fetchData () {
            await Loc.SetLang();
            setappdescription(Loc.currentLang.landingPage.AppDescription);
            setopenApp(Loc.currentLang.landingPage.openApp);
            setTxtWelcome(Loc.currentLang.landingPage.welcomeToActavivo);
            setTxtDescription(
                Loc.currentLang.landingPage.aFreeServiceForCommunityRecreationalandScholasticTeamManagement
            );
            setTxtTermofuse(Loc.currentLang.landingPage.termsOfUse);
            setTxtWhyactavivo(Loc.currentLang.landingPage.whyActavivo);
            settextagreeandterms(Loc.currentLang.landingPage.termsFfAgreeText);
            setTxtSignin(Loc.currentLang.landingPage.signIn);
            setTxtAppleStore(Loc.currentLang.landingPage.appleStore);
            setTxtPrivacy(Loc.currentLang.landingPage.privacyPolicy);
            setTxtGoolgeStore(Loc.currentLang.landingPage.googleStore);
           // setTxtInvite(Loc.currentLang.landingPage.youHaveBeenInvitedtoActavivoby);
            setTxtSocial(
                Loc.currentLang.landingPage.pleaseLoginusinganexistingSocialMediaAccountorcreateanewone
            );
            setTxtNext(Loc.currentLang.landingPage.next);
            setIsPlayerName(Loc.currentLang.landingPage.player);
            setIsParentGuardianname(Loc.currentLang.landingPage.parentGuardiam);
            setIsplayerDescription(
                Loc.currentLang.landingPage.areYouthePlayerorParentGuardianoftheplayer
            );
            setIsInviteName(Loc.currentLang.landingPage.activate);
            setIsNonPlayer(Loc.currentLang.landingPage.nonPlayer);
            if (
                new URLSearchParams(window.location.search) !== null &&
                window.location.pathname?.startsWith('/Follow')
            ) {
                let token = window.location.pathname.replace(/^\/Follow\//, '');
                let val : any = await AV.AVEntity.getTokenRequest(token, 'Follow');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue) {
                    setentityLink(returnvalue);
                    dispatch(setEntityToken(returnvalue));
                }
            }
            else if (
                new URLSearchParams(window.location.search) !== null &&
                window.location.pathname?.startsWith('/Join')
            ) {
                let token = window.location.pathname.replace(/^\/Join\//, '');
                let val : any = await AV.AVEntity.getTokenRequest(token, 'Join');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                    setentityLink(returnvalue);
                    dispatch(setEntityToken(returnvalue));
                }
            }
            else if (
                new URLSearchParams(window.location.search) !== null &&
                window.location.pathname?.startsWith('/Invite')
            ) {
                let token = window.location.pathname.replace(/^\/Invite\//, '');
                let val : any = await AV.AVEntity.getTokenRequest(token, 'Invite');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                    setentityLink(returnvalue);
                    dispatch( setEntityToken( returnvalue ) );
                    setTxtInvite( Loc.currentLang.landingPage.youHaveBeenInvitedtoActavivoby );
                }
            }
            else if (
                new URLSearchParams(window.location.search) !== null &&
                window.location.pathname?.startsWith('/AddStaff')
            ) {
                let token = window.location.pathname.replace(/^\/AddStaff\//, '');
                let val : any = await AV.AVEntity.getTokenRequest(token, 'AddStaff');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {  
                    setentityLink(returnvalue);
                    dispatch( setEntityToken( returnvalue ) );
                    setTxtInvite("Invite Staff Request By ")
                }
            }
            else if (
                new URLSearchParams(window.location.search) !== null &&
                window.location.pathname?.startsWith('/AddPlayer')
            ) {
                let token = window.location.pathname.replace(/^\/AddPlayer\//, '');
                let val : any = await AV.AVEntity.getTokenRequest(token, 'AddPlayer');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                    setentityLink(returnvalue);
                    dispatch( setEntityToken( returnvalue ) );
                    setPathUr(`actavivo://actavivo.com${window.location.pathname}`);
                    console.log( "returnvalue", returnvalue )
                    console.log( "window.location.pathname", window.location.pathname )
                    console.log(`actavivo://actavivo.com${ window.location.pathname }`)
                    setTxtInvite( "Invite Player Request By " )
                }
            }
            else if (
                new URLSearchParams(window.location.search) !== null &&
                window.location.pathname?.startsWith('/Add')
            ) {
                let token = window.location.pathname.replace(/^\/Add\//, '');
                let val : any = await AV.AVEntity.getTokenRequest(token, 'Add');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                    setentityLink(returnvalue);
                    dispatch( setEntityToken( returnvalue ) );
                    setTxtInvite( returnvalue?.type == 'Person' ? "Invite Friend Request By " : returnvalue?.type == 'LeagueTeam' ? "Invite Parent Request By " : "Invite Member Request By " )
                }
            }
            else if (
                new URLSearchParams(window.location.search) !== null &&
                window.location.pathname?.startsWith('/Activate')
            ) {
                let token = window.location.pathname.replace(/^\/Activate\//, '');
                let val : any = await AV.AVEntity.getTokenRequest(token, 'Activate');
                let returnvalue = await val.json();
                setIsActivatepage(returnvalue?.data === 'Player' ? false : true);
                setisShowfollow(false);
                setIsLandingPage(false);
                setIsPlayer(returnvalue?.data === 'Player' ? true : false);
                if (returnvalue) {
                    setentityLink(returnvalue);
                    dispatch(setEntityToken(returnvalue));
                }
            } else {
                setisShowfollow(false);
                setIsLandingPage(false);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            { new URLSearchParams(window.location.search) !== null &&
                window.location.pathname === '/Unsubscribe' ? (
                <RenderUnsubscribe />
            ) : (
                <RenderSignIn />
            ) }
        </>
    );
}
export default React.memo( Signin );

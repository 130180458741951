import { ChevronLeftIcon, ChevronRightIcon, Box, CloseIcon, Tooltip } from 'native-base';
import AVVideo from '../../Platform/AVVideo';
import { Media } from '../../AVCore/Media';
import { AHStack, AVBox, AVPressable, AVStack } from 'components/ui';
import fullscreenimage from '../../assets/images/fullscreen.png';
import normalscreen from '../../assets/images/normalscreen.png';
import React, { FC } from 'react';
import { Pressable } from 'react-native';
import AVCancel from 'components/common/AVCancel';
import { Dimensions } from 'react-native';
import PdfIcon from '../../assets/images/PdfIcon.png';
import docs from '../../assets/images/docs.png';
import xlsimg from '../../assets/images/xlsimg.png';
import MP3Icon from '../../assets/images/MP3ICON.png';
import * as AV from '../../AVCore/AVCore';
import settings from '../../Platform/settings.json';
import { useLocation } from 'react-router-dom';
import { setFeedShowing } from '../../Redux/Actions/userActions';
import { useDispatch } from 'react-redux';
import { DownloadIcon } from '../../assets/icons/icon-assets';

interface Props
{
    slideItems : any | undefined,
    previewitem : any | undefined,
    setpreviewitem : any | undefined,
    type : any | undefined,
    settype : any | undefined,
    fullscreen : any | undefined,
    setIsFullScreen : any | undefined,
    setShowDetails :any | undefined,
    setIscommentItem : any | undefined,
    setIsCommentSlider : any | undefined,
    isCommentSlider : any | undefined,
    handleslideChange : any | undefined,
    isHeader : any | undefined,
    setShowPreview : any | undefined,
    setHeader : any | undefined,
}

const AVImageSlider : FC<Props> = ( { slideItems, previewitem, setpreviewitem, type, settype, fullscreen, setIsFullScreen, setShowDetails, setIscommentItem, setIsCommentSlider, isCommentSlider, handleslideChange, isHeader, setShowPreview, setHeader }) =>
{
    const [ videoUrls, setVideoUrl ] = React.useState( slideItems?.galleryPost ? slideItems?.galleryPost : slideItems );
    const location : any = useLocation();
    const val = slideItems?.galleryPost?.filter((x) => x?.id === slideItems?.media?.id);
    let mediavalue:any = [];
    mediavalue.push(slideItems);
    let slides = ( val?.length === 0 || val == undefined ) && slideItems?.media ? mediavalue : slideItems?.galleryPost?.length > 0 ? slideItems?.galleryPost : slideItems; 
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [play, setplay] = React.useState(slides?.length > 0 ? slides[0] : slides);
    const [imageVIdeo, setimageVIdeo] = React.useState([]);
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions?.width <= 500 || false;
    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        if (slides[0]?.type?.startsWith('video')) {
            setVideoUrl(slides[newIndex]);
            setplay(slides[newIndex]);
            setpreviewitem(slides[newIndex]);
        }
        setplay(slides[newIndex]);
        setpreviewitem(slides[newIndex]);
        setCurrentIndex(newIndex);
        handleslideChange()
    };
    const goToNext = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        if (slides[0]?.type?.startsWith('video')) {
            setVideoUrl(slides[newIndex]);
            setplay(slides[newIndex]);
            setpreviewitem(slides[newIndex]);

        }
        setplay(slides[newIndex]);
        setpreviewitem(slides[newIndex]);
        setCurrentIndex(newIndex);
        handleslideChange()
    };

    const handledocument = async (item) => {
        let val = await AV.Media.getFile( item?.media?.mListId, item?.media?.id, item?.media?.storeId);
        if (val) {
            var blobUrl = val;
            var fileName = item?.media?.orgFile;
            var a = document.createElement('a');
            a.href = blobUrl.toString();
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            //    window.open(val);
        }
    };
    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
  };

  const getFileSource = (file : any = {}) => {
    const fileType : any = file?.type || '';

    if (typeof fileType !== 'string' || !fileType) {
      console.error('Invalid or missing file type.');
      return ''; // Return default value or handle error as needed
    }
        if ( fileType === 'application/pdf' )
        {
            return settings?.pdf;
        } else if ( fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' )
        {
            return settings?.word;
        } else if ( fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' )
        {
            return settings?.powerpoint;
        } else if ( fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' )
        {
            return settings?.excel;
        } else
        {
            return ''; // or any default value if needed
        }
    };

  const imageeeUrl : any = slides != undefined ?(  slides?.length > 0 && slides[currentIndex]?.media?.type?.startsWith('image') ? Media.getURL( slides[ currentIndex ]?.media) : getFileSource(slides[ currentIndex ]?.media)):null;
    const imagessurl = Media.getURL(slideItems?.media);
    if (type !== 'ALL' && slides?.length > 0) {
        setpreviewitem( slides[ currentIndex ] ? slides[ currentIndex ] : play );
    }

    return (
        <>
     {type === 'ALL' && (
                <LoadMedia item={ slideItems } type={ type } settype={ settype } play={ play } handledocument={ handledocument } /> )}
            { type !== 'ALL' && (<div style={ {
                position: isHeader ? 'fixed' : 'relative',
                height: isHeader ? '' : '100%',
                backgroundColor: isHeader ? 'black' : '',
                top: isHeader ? '0px' : '',
                left: isHeader ? '0px' : '',
                right: isHeader ? '0px' : '',
                bottom: isHeader ? '0px' : '',
                zIndex: 999

            } }>
                <div>
                    { slides?.length > 1 && (
                        <>
                            <div onClick={ goToPrevious } style={ {
                                position: 'absolute',
                                top: '50%',
                                transform: 'translate(0, -50%)',
                                left: '15px',
                                fontSize: '45px',
                                color: '#fff',
                                zIndex: 1,
                                cursor: 'pointer',
                            } }>
                                <ChevronLeftIcon size={ '30px' } color={ '#fff' } />
                            </div>
                            <div onClick={ goToNext } style={ {
                                position: 'absolute',
                                top: '50%',
                                transform: 'translate(0, -50%)',
                                right: '15px',
                                fontSize: '45px',
                                color: '#fff',
                                zIndex: 1,
                                cursor: 'pointer',
                            } }>
                                <ChevronRightIcon size={ '30px' } color={ '#fff' } />
                            </div>
                        </>
                    ) }
                </div> )
          { (play?.media?.id && play?.media?.type?.startsWith( 'image' ) ) && (
                    <>
                        { slides?.length >= 1 ? (
                            <div className={ fullscreen ? 'fullscreen-image-container' : isHeader ? 'image-containerHeader' : 'image-container' }>
                                { !fullscreen && !isHeader &&  (
                                    <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                setIsFullScreen( true );
                                            } }
                                        >
                                            <img src={ fullscreenimage } height={ '25px' } width={ '25px' } />
                                        </Pressable>
                                    </AHStack>
                                ) }
                                { !fullscreen && !isHeader && (
                                    <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                setIsFullScreen( true );
                                            } }
                                        >
                                            <img src={ fullscreenimage } height={ '25px' } width={ '25px' } />
                                        </Pressable>
                                    </AHStack>
                                ) }
                                { isHeader && (
                                    <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                setShowPreview( false );
                                                setHeader( false );
                                            } }
                                        >
                                            <CloseIcon size='15px' color={ 'white' } />
                                        </Pressable>
                                    </AHStack>
                                ) }

                                { fullscreen && !isCommentSlider && (
                                    <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                        <Pressable style={ { position: 'absolute', right: '0', top: '0' } }
                                            onPress={ () =>
                                            {
                                                setIsFullScreen( false );
                                            } }
                                        >
                                            <img src={ normalscreen } height={ '25px' } width={ '25px' } />
                                        </Pressable>
                                    </AHStack>
                                ) }

                                <img
                                    src={ imageeeUrl }
                                    alt='slide'
                                    className={ !fullscreen ? 'detail_image' : fullscreen && isMobile ? 'detail_image_mobile' : 'full_image' }
                                />
                            </div>
                        ) : (
                            <div
                                style={ {
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '10px',
                                    backgroundSize: 'contain',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                } }
                            >

                                <img
                                    src={ imageeeUrl }
                                    alt='slide'
                                    style={ { width: '80%', height: '80%', borderRadius: '10px' } }
                                />
                            </div>
                        ) }
                    </>
                ) }
                { ( play?.media?.id &&  !play?.media?.type?.startsWith( 'image' ) && !play?.media?.type?.startsWith( 'video' ) ) && (
                    <>
                        { slides?.length > 0 ? (
                            <div className={ fullscreen ? 'fullscreen-image-container' : isHeader ? 'image-containerHeader' : 'image-container' }>
                                { isHeader && (
                                    <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                setShowPreview( false );
                                                setHeader( false );
                                            } }
                                        >
                                            <CloseIcon size='15px' color={ 'white' } />
                                        </Pressable>
                                    </AHStack>
                                ) }
                                <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                    <Tooltip openDelay={ 500 } label={"Download"} >
                                    <Pressable
                                        onPress={ () =>
                                        {
                                            handledocument( play );
                                        } }
                                    >
                                        <DownloadIcon height={ '25px' } width={ '25px' } />
                                        </Pressable>
                                    </Tooltip>
                                </AHStack>
                                <img
                                    src={ imageeeUrl }
                                    alt='slide'
                                    className={ !fullscreen ? 'detail_image' : fullscreen && isMobile ? 'detail_image_mobile' : 'full_image' }
                                />
                            </div>
                        ) : (
                            <div
                                style={ {
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '10px',
                                    backgroundSize: 'contain',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                } }
                            >

                                <img
                                    src={ imageeeUrl }
                                    alt='slide'
                                    style={ { width: '80%', height: '80%', borderRadius: '10px' } }
                                />
                            </div>
                        ) }
                    </>
                ) }
          { ( play?.media?.id && play?.media?.type?.startsWith( 'video' ) ) && (
                    <div className={ 'video-slide' }>
                        { !fullscreen && !isHeader && (
                            <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                <Pressable onPress={ () => { setIsFullScreen( true ); } }>
                                    <img src={ fullscreenimage } height={ '25px' } width={ '25px' } />
                                </Pressable>
                            </AHStack>
                        ) }
                        { isHeader && (
                            <AHStack position={ 'absolute' } right={ '10%' } top={ '10%' } zIndex={ 999 }>
                                <Pressable onPress={ () => { setShowPreview( false ); setHeader( false ); } }>
                                    <CloseIcon size='15px' color={ 'white' } />
                                </Pressable>
                            </AHStack>
                        ) }
                        { fullscreen && (
                            <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                <Pressable style={ { position: 'absolute', right: '0', top: '0' } }
                                    onPress={ () => { setIsFullScreen( false ); } }>
                                    <img src={ normalscreen } height={ '25px' } width={ '25px' } />
                                </Pressable>
                            </AHStack>
                        ) }
                        <Box width={ '100%' } height={ fullscreen ? '100%' : '94%' }>
                            <video
                                controls
                                autoPlay
                                width={ '100%' }
                                height={ '100%' }
                                src={ Media.getURL( play?.media ) }
                                className={ fullscreen ? 'full_video' : '' }
                            />
                        </Box>
                    </div>
                ) }
            </div>)}
        </>
    )
}
const LoadMedia = ( { item, type, settype, play, handledocument }) => {
  const [imageUrls, setImagesUrl] = React.useState([]);
    const [ videoUrls, setVideoUrl ] = React.useState( [] );
    const [ DocsUrl, setDocsUrl ] = React.useState( [] );

  let videomedia:any = [];
  let imagemedia : any = [];
  let DocsMedia : any = [];

  React.useEffect(() => {
    if (
      ((item?.galleryPost && item?.galleryPost?.length > 0 )||
        item?.media?.type?.startsWith('image')) ||
      (item?.media?.storeId && item?.media?.type?.startsWith('image'))
    ) {
      for (let i = 0; i < item?.galleryPost?.length; i++) {
        imagemedia.push(item?.galleryPost[i].media);
        }
        let images = imagemedia?.length && imagemedia.map( ( i ) => Media.getURL( i ) );
        setImagesUrl( images );
      if (item?.media?.storeId && item?.media?.type?.startsWith('image')) {
        imagemedia.push(item?.media);
        let images = imagemedia?.length && imagemedia.map((i) => Media.getURL(i));
        setImagesUrl(images);
      }
    }
    else if ( !item?.media?.type?.startsWith( 'image' ) && !item?.media?.type?.startsWith( 'video' ) && item?.galleryPost && item?.galleryPost?.length > 0 )
    {
        {
            for ( let i = 0; i < item?.galleryPost?.length; i++ )
            {
                DocsMedia.push( item?.galleryPost[ i ].media );

            }
            setDocsUrl( DocsMedia );
        }
    }
    else if ( !item?.media?.type?.startsWith( 'image' ) && !item?.media?.type?.startsWith( 'video' ) && item?.media?.type?.startsWith( 'application' ) )
    {
        DocsMedia.push( item?.media )
        setDocsUrl( DocsMedia );
    }
    else
    {
      for (var j = 0; j < item?.galleryPost?.length; j++) {
        if (
          item?.galleryPost[j]?.media?.storeId &&
          item?.galleryPost[j]?.media?.type?.startsWith('video')
        ) {
          videomedia.push(item?.galleryPost[j]?.media);
          setVideoUrl(videomedia);
        } else {
          imagemedia.push(item?.galleryPost[j]?.media);
          let images = imagemedia?.length && imagemedia.map((i) => Media.getURL(i));
          setImagesUrl(images);
        }
      }
      if (item?.media?.storeId && item?.media?.type?.startsWith('video')) {
        videomedia.push(item?.media);
        setVideoUrl(videomedia);
      }
    }
  }, [ item ] );

    const getFileSource = ( url : { type ?: string } ) =>
    {
        const { type } = url;
        if ( type === 'application/pdf' )
        {
            return settings.pdf;
        } else if ( type === 'application/msword' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' )
        {
            return settings.word;
        } else if ( type === 'application/vnd.ms-powerpoint' || type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' )
        {
            return settings.powerpoint;
        } else if ( type === 'application/vnd.ms-excel' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' )
        {
            return settings.excel;
        } else
        {
            return ''; // or any default value if needed
        }
    };

  return (
    <AVStack width={'100%'}>
          <div >
        {imageUrls && imageUrls.length > 0 && (
          <div
            className={
              imageUrls.length === 1 && type !== 'ALL'
                ? 'grid_1'
               : imageUrls.length === 1 && type == 'ALL'
                ? 'grid3'
                : imageUrls.length === 2 && type !== 'ALL'
                ? 'grid_22'
                : imageUrls.length === 2  && type === 'ALL'
                ? 'grid3'
                 : imageUrls.length === 3 && type !== 'ALL'
                ? 'preview32'
                 : imageUrls.length === 3 && type === 'ALL'
                ? 'grid3'
                : imageUrls.length === 4  && type !== 'ALL'
                ? 'grid_42'
                : imageUrls.length === 4 && type === 'ALL'
                ? 'grid3'
                : imageUrls.length >= 5 && type !== 'ALL'
                ? 'grid_5'
                        :'default'
            }
          >       
            <Pressable          
              onPress={() => {
                settype('IMG');
              }}
            >
             { imageUrls.slice( 0, type == 'ALL' ? (item?.galleryPost?.length || item?.media?.length) :5).map((url, i) => (
                <img
                  src={url}
                  key={`${url}_${i}`}
                  alt=''
                  className={`img-${i}`}
                  style={{ margin: '1px' }}
                />
              ))}
            </Pressable>
            {imageUrls.length > 5 && (
                          <div className={ type === 'ALL' ? '' : 'more-pics-shadow' }>+{imageUrls.length - 5}</div>
            )}
          </div>
        )}
      </div>
      <div>
        {videoUrls && videoUrls.length > 0 && (
          <div
            className={
              videoUrls.length === 1
                ? 'grid_1'
                : videoUrls.length === 2
                ? 'grid_2'
                : videoUrls.length === 3
                ? 'preview3'
                : videoUrls.length === 4
                ? 'grid_4'
                : videoUrls.length >= 5 
                        ? 'grid_5'
                        :'default'
            }
          >
            {videoUrls.slice(0, 5).map((vid, i) => (
                <video
                    controls
                    autoPlay
                    width={ '100%' }
                    height={ '100%' }
                    src={ Media.getURL( vid ) }
                    key={ i }
                    className={ `video img-${ i }` }
                />
            ))}

            {videoUrls.length > 5 && (
              <div className='more-pics-shadow'>+{videoUrls.length - 5}</div>
            )}
          </div>
        )}
      </div>
          <div>
              { DocsUrl && DocsUrl.length > 0 && (
                  <div
                      className={
                          DocsUrl.length === 1
                              ? 'doc_1'
                              : DocsUrl.length === 2
                                  ? 'grid_22'
                                  : DocsUrl.length === 3
                                      ? 'preview32'
                                      : DocsUrl.length === 4
                                          ? 'grid_42'
                                          : DocsUrl.length >= 5
                                              ? 'grid_5'
                                              : 'default'
                      }
                  >
                      { DocsUrl.slice( 0, type == 'ALL' ? ( item?.galleryPost?.length || item?.media?.length ) :5 ).map( ( url : { type ?: string }, i ) => (
                          <>
                              <AHStack position={ 'absolute' } right={ '0' } top={ '0' } backgroundColor={'red'}>
                                    <Tooltip openDelay={ 500 } label={"Download"} >
                                    <Pressable
                                          onPress={ () =>
                                          {
                                              handledocument( play );
                                          } }
                                    >
                                        <DownloadIcon height={ '25px' } width={ '25px' } />
                                        </Pressable>
                                    </Tooltip>
                                </AHStack>
                    
                          <img
                              src={getFileSource( url )}
                              key={ `${ url }_${ i }` }
                              alt=''
                              className={ `img-${ i }` }
                              style={ { margin: '1px' } }
                              />
                          </>
                      ) ) }
                      { DocsUrl.length > 5 && (
                          <div className='more-pics-shadow'>+{ DocsUrl.length - 5 }</div>
                      ) }
                  </div>
              ) }
          </div>
    </AVStack>
  );
};

export default AVImageSlider;

import
    {
        AddIcon,
        Box,
        CloseIcon,
        Divider,
        HStack,
        Image,
        Pressable,
        ScrollView,
        Text,
        VStack,
        Button
    } from 'native-base';
import React, { FC, useRef } from 'react';
import { Media } from '../../AVCore/Media';
import {  StyleSheet } from 'react-native';
import AVAdd from 'components/common/AVAdd';
import { AVButtonRoundedFull, AVButtonRoundInvert } from 'components/common/AVButtons';
import AVCancel from 'components/common/AVCancel';
import AVEmoji from 'components/common/AVEmoji';
import './style.css';
import { AVBase, Post } from '../../AVCore/AVCore';
import { Loc } from 'App/Loc';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import * as AV from 'AVCore/AVCore';
import { AddPhotoIcon } from 'assets/icons/icon-assets';
import { setFeeds, setFeedsUpdate } from '../../Redux/Actions/feedsAction';
import Dropdown from '../../assets/images/dropdown.svg';
import { useWindowDimension } from 'Utility/WindowDimensions';
import AVEmojiPicker from '../../components/common/AVEmojiPicker';
import settings from '../../Platform/settings.json';
import MP3Icon from '../../assets/images/MP3ICON.png';
import { setisActive } from '../../Redux/Actions/userActions';
import { isMobile } from 'react-device-detect';

interface Props {
    setShowCreatePost : any | undefined;
    windowDimensions : any | undefined;
    headerHeight : any | undefined;
    item : any | undefined;
    showModal : any | undefined;
    reload : any | undefined;
    setItem : any | undefined;
    setShowDetails : any | undefined;
}

const EditPostpopup : FC<Props> = ({
    setShowCreatePost,
    headerHeight,
    reload,
    windowDimensions,
    item,
    setItem,
    setShowDetails,
    showModal
}) => {
    const dimensions = useWindowDimension();
    const mobile = dimensions?.width < 600 || false;
    const dispatch = useDispatch();
    const currentUser = useSelector((state : RootStateOrAny) => state.user.user[ 0 ]);
    const [ saveitem, setsaveitem ] = React.useState(item);
    const [ showAddMedia, setShowAddMedia ] = React.useState(false);
    const [ images, setImages ] = React.useState<any>([]);
    const [ docs, setDocs ] = React.useState<any>([]);
    const [ media, setMedia ] = React.useState<any>([]);
    const [ error, setError ] = React.useState('');
    const [ videos, setvideos ] = React.useState<any>([]);
    const [ picker, setPicker ] = React.useState(false);
    const [ text, setText ] = React.useState(item?.text);
    const [ cursorPosition, setCursorPosition ] = React.useState();
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ isImageProcessing, setIsImageProcessing ] = React.useState(false);
    const [ alertMessage, setAlertMessage ] = React.useState('');
    const [ noticeMessage, setNoticeMessage ] = React.useState<any>('');
    const [ successMessage, setSuccessMessage ] = React.useState<any>(null);
    const [ isActive, setIsActive ] = React.useState(false)
    const [ visibility, setvisibility ] = React.useState( currentUser?.preferences?.defaultPostVisibility == "Follow" ? "Followers" : currentUser?.preferences?.defaultPostVisibility ? currentUser?.preferences?.defaultPostVisibility  : item?.visibility === "Follow" ? "Followers" : item?.visibility);
    const imageInputRef : any = React.useRef(null);
    const textEditRef : any = React.useRef();
    const userFeeds = useSelector((state : RootStateOrAny) => state.feeds.feeds[ 0 ]);
    const [ showEditFilePicker, setShowEditFilePicker ] = React.useState(false);
    const [ deletepostlist, setdeletepostlist ] = React.useState<any>([]);
    const [ isTrue, setIsTrue ] = React.useState(false);

    const handleDrop = async (e) => {
        e.preventDefault();

        const doc = e.dataTransfer.files;
        const fileInput : any = imageInputRef.current;
        let files : any = Array.from(doc);
        const updatedFiles = files.map((file : any) => {
            const fileName = file.name;
            if (fileName.toLowerCase().endsWith('.jfif')) {
                const updatedFileName = fileName.replace(/\.\w+$/, '.jpg');
                const updatedFile = new File([ file ], updatedFileName, { type: "image/jpeg" });
                return updatedFile;
            }
            else {
                return file;
            }
        });
        files = updatedFiles;
        setShowAddMedia(false);
        files.forEach((img : any) => {
            if (
                img.type !== 'image/jpeg' &&
                img.type !== 'image/jfif' &&
                img.type !== 'image/png' &&
                img.type !== 'image/webp' &&
                img.type !== 'image/gif' &&
                img.type !== 'video/mp4' &&
                !img?.type?.startsWith('application')

            ) {
                setError(`${ img.name } format is unsupported ! only Jpeg,Jfif, Png, Webp, Gif are allowed.`);
                files = files.filter((item : any) => item.name !== img.name);
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = (readerEvent) => {
                    /*setImages((images) => [ ...images, readerEvent.target.result ]);*/
                };
            }
        });
        setIsImageProcessing(true);
        setNoticeMessage('Please wait while we process your images...');
        const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
        setMedia((prev) => [ ...prev, ...data ]);
        data?.forEach((img) => {
            if (img.type.startsWith('image')) {
                setImages((images) => [ ...images, img ]);
            }
            else if (img.type.startsWith('application')) {
                setDocs((images) => [ ...images, img ])
            }
            else setvideos((videos) => [ ...videos, img ]);
        });
        setNoticeMessage(null);
        setIsImageProcessing(false);
        fileInput.value = null;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleImages = async (e : any) => {
        const fileInput : any = imageInputRef.current;
        let files : any = Array.from(e?.target?.files);
        const updatedFiles = files.map((file : any) => {
            const fileName = file.name;
            if (fileName.toLowerCase().endsWith('.jfif')) {
                const updatedFileName = fileName.replace(/\.\w+$/, '.jpg');
                const updatedFile = new File([ file ], updatedFileName, { type: "image/jpeg" });
                return updatedFile;
            }
            else {
                return file;
            }
        });
        files = updatedFiles;
        setShowAddMedia(false);
        files.forEach((img : any) => {
            if (
                img.type !== 'image/jpeg' &&
                img.type !== 'image/jfif' &&
                img.type !== 'image/png' &&
                img.type !== 'image/webp' &&
                img.type !== 'image/gif' &&
                img.type !== 'video/mp4' &&
                !img?.type?.startsWith('application')

            ) {
                setError(`${ img.name } format is unsupported ! only Jpeg,Jfif, Png, Webp, Gif are allowed.`);
                files = files.filter((item : any) => item.name !== img.name);
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = (readerEvent) => {
                    /*setImages((images) => [ ...images, readerEvent.target.result ]);*/
                };
            }
        });
        setIsImageProcessing(true);
        setNoticeMessage('Please wait while we process your images...');
        const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
        setMedia((prev) => [ ...prev, ...data ]);
        data?.forEach((img) => {
            if (img.type.startsWith('image')) {
                setImages((images) => [ ...images, img ]);
            }
            else if (img.type.startsWith('application')) {
                setDocs((images) => [ ...images, img ])
            }
            else setvideos((videos) => [ ...videos, img ]);
        });
        setNoticeMessage(null);
        setIsImageProcessing(false);
        fileInput.value = null;
    };

    const handleUpdatePost = async (e : any) => {
        let reloading = null;
        setIsLoading(true);
        for (var i = 0; i < deletepostlist.length; i++) {
            let FK : any = {
                pk: deletepostlist[ i ].pk, id: deletepostlist[ i ].id, region: deletepostlist[ i ].region, type: deletepostlist[ i ].type
            };
            await Post.delete(FK);
        };
        let media : any = [];
        images?.forEach((ele) => {
            media.push(ele);
        });
        videos?.forEach((ele) => {
            media.push(ele);
        });
        docs.forEach((ele) => {
            media.push(ele);
        });
        e.preventDefault();
        let gallery = { layout: null, Items: [] };
        let galleryArray : any = [];
        item.gallery = gallery;
        item.text = text;
        item.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
        if ( !item?.media || media.length === 0 )
            item.media = null;
        if (media && media.length === 1) {
            item.media = media[ 0 ];
        }
        if (media && media.length > 1) {
            let galleryPost : any = [];
            item.media = null;
            for (var i = 0; i < media.length; i++) {
                let galleryPostlist : any = item.galleryPost?.filter((x) => x.media.id == media[ i ].id);
                if (galleryPostlist?.length === 0 || galleryPostlist === undefined) {
                    let param1 : AV.IFeedParam = {
                        feed: AVBase.getFk(item.owner),
                        startDate: new Date(0),
                        forward: true
                    };
                    if (param1?.feed?.id) {
                        let post1 : any = { pk: param1?.feed?.id, region: param1?.feed?.region };
                        post1.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
                        post1.parentPost = item.id;
                        post1.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
                        post1.media = media[ i ];
                        post1.ptype = "G";
                        let childresponse = await Post.save(post1);
                        dispatch(setisActive(true));
                        galleryArray.push(childresponse.id);
                        galleryPost.push(childresponse);
                    }
                } else {
                    galleryArray.push(item.galleryPost?.filter((x) => x.media.id == media[ i ].id)[ 0 ].id);
                    galleryPost.push(item.galleryPost?.filter((x) => x.media.id == media[ i ].id)[ 0 ]);
                }
            }
            item.gallery.Items = galleryArray;
            item.galleryPost = [];
            let val : any = await AV.Post.save(item);
            dispatch(setisActive(true));
            item.galleryPost = galleryPost;
            val.galleryPost = galleryPost;
            if (item?.children?.length > 0)
                val.children = item.children;
            if (item?.impressions?.length > 0)
                val.impressions = item.impressions;
            setsaveitem(val);
            await FeedsUpdate(val);
            setIsLoading(false);
            setSuccessMessage('Your post has been published!');
            setShowCreatePost(false);
            return;
        }

        let val : any = await AV.Post.save(item);
        dispatch(setisActive(true));
        if (val) {
            if (item?.children?.length > 0 ? true : false)
                val.children = item.children;
            if ( item?.impressions?.length > 0 ? true : false )
                val.impressions = item.impressions;
            if ((val?.galleryPost?.length == 0 || val?.galleryPost?.length == undefined) || (val?.media?.id?.trim()?.length == 0 || val?.media?.id?.trim()?.length == undefined)) {
                setShowDetails(false);
            }
            setsaveitem(val);
            setItem( val );
            let feeds = userFeeds.filter( x => x.id == val.id );
            if ( feeds?.length > 0 )
            {
                feeds = userFeeds?.filter( x => x?.id !== val?.id );
                dispatch( setFeeds( feeds ) );
            }
            else
            {
                const updatedParentPost = await replaceChildrenInParent( val?.id, val?.parentPost, userFeeds, val );
                let exciting = userFeeds?.filter( x => x.id == updatedParentPost?.id );
                userFeeds[ userFeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                dispatch( setFeeds( userFeeds ) );
                setMedia( [] );
                setIsLoading( false );
                setSuccessMessage( 'Your post has been published!' );
                setShowCreatePost( false );
                return
            }
            feeds.unshift( val );
            dispatch( setFeeds( feeds ) );
            dispatch( setFeedsUpdate( true ) );
        }
        setMedia([]);
        setIsLoading(false);
        setSuccessMessage('Your post has been published!');
        setShowCreatePost(false);
    };

    const FeedsUpdate = async (val) => {
        setItem(val);
        let feeds = userFeeds.filter(x => x.id == val.id);
        if (feeds?.length > 0) {
            userFeeds[ userFeeds.indexOf(feeds[ 0 ]) ] = val
        }
        else {
            const updatedParentPost = await replaceChildrenInParent(val?.id, val?.parentPost, userFeeds, val);
            let exciting = userFeeds?.filter(x => x.id == updatedParentPost?.id);
            userFeeds[ userFeeds.indexOf(exciting[ 0 ]) ] = updatedParentPost
        }
        dispatch(setFeeds(userFeeds));
        dispatch(setFeedsUpdate(true));
    }

    const replaceChildrenInParent = (postId, parentpostId, nestedData, newChildren) => {
        for (const item of nestedData) {
            if (item.id === parentpostId) {
                if (!item?.children)
                    item.children = [];
                let val = item.children.filter(x => x?.id == postId);
                if (val?.length > 0)
                    item.children[ item.children.indexOf(val[ 0 ]) ] = newChildren
                else
                    item.children.push(newChildren);
                return item;
            }

            if (item.children && item.children.length > 0) {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent(postId, parentpostId, item.children, newChildren);
                if (updatedChild) {
                    return item; // Return the parent with updated children
                }
            }
            if (item?.galleryPost && item.galleryPost.length > 0) {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent(postId, parentpostId, item.galleryPost, newChildren);
                if (updatedChild) {
                    return item; // Return the parent with updated children
                }
            }

        }
        return null; // Parent post not found
    };

    const handleDeleteImage = async (media) => {
        //if (saveitem.media?.id == media.id) {
        //    saveitem.media = null;
        //    await Post.save(saveitem);
        //    dispatch(setisActive(true));
        //} 
            let deletepost : any = item?.galleryPost?.filter((x) => x?.media?.id == media?.id);
            if (deletepost?.length > 0)
                deletepostlist.push(deletepost[ 0 ]);
        
        await handlecancelimage(media);
    };

    const handlecancelimage = (img) => {
        setMedia(media.filter((f) => f != img));
        setDocs(docs.filter((f) => f != img))
        setImages(images.filter((f) => f != img));
        setvideos(videos.filter((f) => f != img));
    };

    const handeEmojiPicker = (e) => {
        const ref : any = textEditRef.current;
        ref.focus();
        const start = text?.substring(0, ref.selectionStart);
        const end = text?.substring(ref.selectionStart);
        const newText = start + e?.native + end;
        setText(newText);
        setPicker(!picker);
        const cursor = start.length + e?.native.length;

        ref.setSelectionRange(cursor, cursor)
    }

    const Grid2styles = StyleSheet.create({
        container: { flex: 1, flexDirection: 'row', flexWrap: 'wrap', minwidth: '120px' },
        img1: {
            backgroundColor: 'lightblue',
            borderColor: '#fff',
            borderWidth: 1,
            width: '340px',
            height: 230,

        },
    });

    const Grid1styles = StyleSheet.create({
        container: { flex: 1, flexDirection: 'row', flexWrap: 'wrap', minwidth: '120px' },
        vid1: {
            backgroundColor: 'black',
            borderColor: '#fff',
            borderWidth: 1,
            width: '340px',
            height: 'auto',
        },
    });

    const menuRefs = useRef(null);

    useOutsideAlerter(menuRefs);
    function useOutsideAlerter (ref) {
        React.useEffect(() => {
            function handleClickOutside (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsActive(false);
                    setPicker(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }

    React.useEffect(() => {
        let img : any = [];
        let videoUrl : any = [];
        let docsUrl : any = [];
        if (item?.media?.type?.startsWith('image')) img.push(item.media);
        else if (item?.media?.type?.startsWith('video')) videoUrl.push(item.media);
        else if (item?.media?.type?.startsWith('application')) docsUrl.push(item.media);

        item?.galleryPost?.map((item1) => {
            if (item1?.media?.type?.startsWith('image')) img.push(item1.media);
            else if (item1?.media?.type?.startsWith('video')) videoUrl.push(item1.media);
            else if (item1?.media?.type?.startsWith('application')) docsUrl.push(item1.media);
        });
        setImages(img);
        setvideos(videoUrl);
        setDocs(docsUrl);
        if (img.length > 0 || videoUrl.length > 0 || docs.length > 0) {
            setShowEditFilePicker(true);
        }
    }, []);

    React.useEffect(() => {
        if (textEditRef.current && !isTrue) {
            textEditRef.current.focus();
            textEditRef.current.setSelectionRange(text?.length, text?.length);
        }
        setIsTrue(true)
    }, [ showModal ]);

    return (
        <Box
            position={ 'fixed' }
            top={ 0 }
            bottom={ 0 }
            left={ 0 }
            right={ 0 }
            backgroundColor={ 'AVColor.overlay2' }
            zIndex={ 99999 }
        >
            <div
                className={ `create_post_popup ${ mobile && 'create_post_popup_mobile' }` }
                style={ {
                    width: mobile ? dimensions.width - 15 : '38%',
                    maxHeight: mobile ? '650px' : '700px',
                    marginTop: '14px'
                } }
            >
                { picker && (
                    <Box position={ 'absolute' } ref={ menuRefs } zIndex={ 99 } borderColor={ "#2FA3BE" } borderStyle={ "solid" } borderWidth={ "5px" } width={ mobile ? '85%' : '88%%' }>
                        <AVEmojiPicker onEmojiSelect={ (e) => { return handeEmojiPicker(e); } } />
                    </Box>
                ) }
                <VStack>
                    <HStack
                        alignItems={ 'center' }
                        justifyContent={ 'space-between' }
                        px={ 5 }
                        marginTop={ '20px' }
                    >
                        <Text fontSize={ 24 } > { item?.parentPost ? Loc.currentLang.post.editComment : Loc.currentLang.post.editPost }</Text>
                        <AVCancel callback={ setShowCreatePost } color={ undefined } size={ undefined } />
                    </HStack>
                    <ScrollView showsHorizontalScrollIndicator={ false } maxHeight={ mobile ? '400px' : '460px' }>
                        { item?.parentPost === undefined && <HStack marginLeft={ '24px' } marginTop={ '12px' } space={ 3 } >
                            <Text fontSize={ '18' }>{ Loc.currentLang.menu.whoCanView }:</Text>
                            <HStack
                                ref={ menuRefs }
                            >
                                <HStack>
                                    <Button
                                        width={ '160px' }
                                        bgColor={ '#DAF2F4' }
                                        rounded='full'
                                        fontWeight='550px'
                                        color='#fff'
                                        p={ 2 }
                                        justifyContent={ 'flex-start' }
                                        minHeight='35px'
                                        borderRadius={ '20px' }
                                        borderColor={ 'AVColor.primary' }
                                        onPress={ () => {
                                            setIsActive(!isActive)
                                        } }
                                    >
                                        <Text color={ '#2FA3BE' } lineHeight={ '1rem' } paddingLeft={ '18px' } >{item.visibility == "Follow" ? "Followers" : item.visibility}</Text>
                                    </Button>
                                    <Pressable position={ 'absolute' } right={ 0 } top={ 2 } onPress={ () => {
                                        setIsActive(!isActive)
                                    } } >
                                        <Image source={ Dropdown } alt='Actavivo' size='25px' />
                                    </Pressable>
                                </HStack>
                                <HStack>
                                    { isActive && (<HStack position={ 'absolute' } right={ 0 } top={ '39px' } flexDirection={ 'column' } width={ '156px' } borderRadius={ '5px' } bgColor='#FFFFFF' shadow='9' rounded='lg' zIndex={ 100 } >
                                        <Button onPress={ () => { item.visibility = "Private"; setvisibility("Private"); setIsActive(!isActive); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '156px' } ><Text color={ 'black' } >{ Loc.currentLang.menu.private }</Text></Button>
                                        <Button onPress={ () => { item.visibility = "Public";  setvisibility("Public"); setIsActive(!isActive); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '156px' }><Text color={ 'black' }>{ Loc.currentLang.menu.public }</Text></Button>
                                        <Button onPress={ () => { item.visibility = "Followers"; setvisibility("Followers"); setIsActive(!isActive); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '156px' }><Text color={ 'black' } >{ Loc.currentLang.menu.followers }</Text></Button>
                                    </HStack>
                                    ) }
                                </HStack>
                            </HStack>
                        </HStack> }
                        <div>
                            <Box
                                top={ 2 }
                                my={ 3 }
                                mx={ 5 }
                                borderWidth={ 1 }
                                borderRadius={ 5 }
                                overflow={ 'hidden' }
                                borderColor={ 'AVColor.grey' }
                                zIndex={ isActive ? '-1' : '0' }
                            >
                                <textarea
                                    ref={ textEditRef }
                                    value={ text }
                                    maxLength={ 1000 }
                                    style={ {
                                        border: 'none',
                                        resize: 'none',
                                        height: mobile ? '20px' : '75px',
                                        padding: '20px 15px',
                                        outline: 'none',
                                        fontFamily: 'inherit',
                                        fontSize: 'inherit',
                                    } }
                                    placeholder={ Loc.currentLang.post.createPostPlaceholder }
                                    onChange={ (e) => setText(e.target.value) }
                                    onKeyDown={ (e) => {
                                        if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                                            e.preventDefault();
                                            handleUpdatePost(e);
                                        }
                                    } }
                                    autoFocus
                                ></textarea>

                                <Text textAlign={ 'right' }>
                                    <AVEmoji showEmoji={ () => setPicker((prev) => !prev) } />
                                </Text>
                            </Box>

                            <input
                                type='file'
                                accept=''
                                multiple
                                hidden
                                ref={ imageInputRef }
                                onChange={ handleImages }
                            />
                            { showAddMedia && (
                                <Pressable onPress={ () => imageInputRef?.current.click() }>

                                    <Box
                                        my={ 3 }
                                        mx={ 5 }
                                        borderWidth={ 1 }
                                        borderRadius={ 5 }
                                        overflow={ 'hidden' }
                                        borderColor={ 'avcolors.black' }
                                        borderStyle={ 'dashed' }
                                        maxH={ '300px' }
                                        overflowY={ 'auto' }
                                        zIndex={ 0 }
                                    >
                                        <div
                                            className="image-drop-zone"
                                            onDrop={ handleDrop }
                                            onDragOver={ handleDragOver }
                                        >
                                            <VStack
                                                alignItems={ 'center' }
                                                justifyContent={ 'center' }
                                                minH={ '150px' }
                                                space={ 2 }
                                            >
                                                <Box backgroundColor={ 'AVColor.grey' } p={ 2 } borderRadius='full'>
                                                    <AddIcon color={ 'AVColor.secondary' } />
                                                </Box>
                                                <Text fontSize={ 14 } color={ 'AVColor.grey' }>
                                                    { Loc.currentLang.menu.imageOrVideo }
                                                </Text>
                                            </VStack>
                                            <HStack position={ 'absolute' } right={ '0' } top={ '0px' }>
                                                <AVCancel callback={ () => setShowAddMedia((prev) => !prev) } color={ undefined } size={ undefined } />
                                            </HStack>
                                        </div>
                                    </Box>
                                </Pressable>



                            ) }
                            <HStack my={ 3 } mx={ 5 } space={ 4 } alignItems="center" zIndex={ 0 }>
                                { images?.length > 0 || videos?.length > 0 || docs?.length > 0 ? (
                                    <ScrollView maxHeight={ 300 }>
                                        <HStack alignSelf="flex-start" alignItems="center" space={ 3 }>
                                            <AVAdd addEvent={ () => imageInputRef.current.click() } />
                                            <div
                                                className="image-drop-zone"
                                                onDrop={ handleDrop }
                                                onDragOver={ handleDragOver }
                                            >
                                                <Text
                                                    variant="AVSubheader"
                                                    color="avcolors.secondaryTextColor"
                                                    textAlign="center"
                                                >
                                                    { Loc.currentLang.post.addToYourPost }
                                                </Text>
                                            </div>
                                        </HStack>
                                        <HStack
                                            alignContent="center"
                                            alignSelf="center"
                                            justifyContent="space-around"
                                            space={ 3 }
                                        >
                                            <Box style={ Grid2styles.container }>
                                                { images?.slice(0, 20).map((media, i) => (
                                                    <HStack key={ media.id + i } style={ Grid2styles.img1 }>
                                                        <Pressable
                                                            position="absolute"
                                                            background="#dcdc"
                                                            borderRadius="50px"
                                                            zIndex={ 4 }
                                                            padding={ 2 }
                                                            right={ 0 }
                                                            onPress={ () => handleDeleteImage(media) }
                                                        >
                                                            <CloseIcon color="#fff" size={ 5 } />
                                                        </Pressable>
                                                        <Image
                                                            source={ { uri: Media.getURL(media) } }
                                                            alt="Post Image"
                                                            style={ { width: '100%', height: '100%' } }
                                                        />
                                                    </HStack>
                                                )) }

                                                { docs?.slice(0, 20).map((media, i) => (
                                                    <HStack key={ media.id + i } style={ Grid2styles.img1 }>
                                                        <Pressable
                                                            position="absolute"
                                                            background="#dcdc"
                                                            borderRadius="50px"
                                                            zIndex={ 4 }
                                                            padding={ 2 }
                                                            right={ 0 }
                                                            onPress={ () => handleDeleteImage(media) }
                                                        >
                                                            <CloseIcon color="#fff" size={ 5 } />
                                                        </Pressable>
                                                        <img
                                                            style={ { width: '100%', height: '100%', objectFit: 'contain' } }
                                                            src={
                                                                media?.type === 'application/pdf'
                                                                    ? settings?.pdf
                                                                    : media?.type ===
                                                                        'application/vnd.ms-powerpoint' ||
                                                                        media?.type ===
                                                                        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                                                                        ? settings?.powerpoint
                                                                        : media?.type === 'application/msword' ||
                                                                            media?.type ===
                                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                            ? settings?.word
                                                                            : media?.type === 'audio/wav'
                                                                                ? MP3Icon
                                                                                : media?.type ===
                                                                                    'application/vnd.ms-excel' ||
                                                                                    media?.type ===
                                                                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                                                    ? settings.excel
                                                                                    : settings.file
                                                            }
                                                            alt=""
                                                        />
                                                    </HStack>
                                                )) }

                                                { videos?.slice(0, 20).map((img, i) => (
                                                    <HStack key={ img.id + i } style={ Grid1styles.vid1 }>
                                                        <Pressable
                                                            position="absolute"
                                                            background="#dcdc"
                                                            borderRadius="50px"
                                                            zIndex={ 4 }
                                                            padding={ 2 }
                                                            right={ 0 }
                                                            onPress={ () => handleDeleteImage(img) }
                                                        >
                                                            <CloseIcon color="#fff" size={ 5 } />
                                                        </Pressable>
                                                        <video
                                                            controls
                                                            autoPlay
                                                            width="100%"
                                                            height="100%"
                                                            src={ Media.getURL(img) }
                                                        />
                                                    </HStack>
                                                )) }
                                            </Box>
                                        </HStack>
                                    </ScrollView>
                                ) : (
                                    !showAddMedia && (
                                        <Pressable
                                            onPress={ () => setShowAddMedia((prev) => !prev) }
                                            _hover={ { bgColor: 'AVColor.hovercolor2', borderRadius: 'md' } }
                                            p={ 1 }
                                            px={ 1.5 }
                                        >
                                            <HStack alignItems="center" space={ 3 }>
                                                <AddPhotoIcon size="24px" color="#32AE78" />
                                                <Text variant="AVSubheader" color="AVColor.secondaryTextColor">
                                                    { Loc.currentLang.post.addToYourPost }
                                                </Text>
                                            </HStack>
                                        </Pressable>
                                    )
                                ) }
                            </HStack>

                        </div>
                    </ScrollView>
                    { (alertMessage || successMessage) && (
                        <HStack alignItems={ 'center' } justifyContent={ 'center' }>
                            <Text fontSize={ 16 } color={ alertMessage ? 'danger.600' : 'success.600' }>
                                { alertMessage || successMessage }
                            </Text>
                        </HStack>
                    ) }
                    <Divider my={ 3 } px={ 3 } />
                    <HStack justifyContent={ 'center' } pr={ 3 } mb={ 4 } space={ 3 }>
                        <>
                            <AVButtonRoundInvert btnText={ Loc.currentLang.menu.cancel } callback={ () => setShowCreatePost(false) } px={ undefined } height={ undefined } isLoadingText={ undefined } isDisabled={ undefined } isLoading={ undefined } loading={ undefined } />
                            <AVButtonRoundedFull
                                btnText={ Loc.currentLang.menu.publish }
                                isDisabled={ ( ( text?.trim()?.length === 0 || text?.trim()?.length === undefined ) && images?.length === 0 && videos?.length === 0) }
                                callback={ handleUpdatePost }
                                loading={ isLoading || isImageProcessing }
                                isLoadingText={ isImageProcessing ? 'Processing...' : 'Publishing your post' } px={ undefined } height={ undefined } isLoading={ undefined } />
                        </>
                    </HStack>
                </VStack>
            </div>
        </Box>
    );
};

export default EditPostpopup;

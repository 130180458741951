import React, { FC, useState } from 'react';
import { Box, VStack, HStack, Text, Pressable, Stack, Checkbox, Button, View } from 'native-base';
import { Loc } from '../../App/Loc';
import { CrossIcon } from '../../assets/icons/icon-assets';
import AVInputtext from 'components/AVInputtext';
import AVDatePicker from '../../components/common/AVDatePicker';
import AVTimePicker from '../../components/common/AVTimePicker';
import dayjs from 'dayjs';
import * as AV from '../../AVCore/AVCore';
import _ from 'lodash';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { Dimensions } from 'react-native';
import * as AVI from '../../AVCore/Interfaces/AV.Interfaces';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { setEntitydata, setUser } from '../../Redux/Actions/userActions';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AVLocationBox from '../common/AVLocationBox';
import CustomToast from '../../Hooks/useCustomToast';
import AddandEditLinks from './AddandEditLinks';
interface Props
{
    Entity : any,
    setShowAddEventsModal : React.Dispatch<React.SetStateAction<boolean>>,
    repeatWeek : boolean | undefined,
    setRepeatWeek : any | undefined,
    isGame : boolean | undefined,
    typeevent : any | undefined,
    isPractice : boolean | undefined,
    datafield : any | undefined,
    showFields : any | undefined,
    setShowFields : any | undefined,
    updateField : any | undefined,
    setIsUpdateField : any | undefined,
    setIsShowField : any | undefined,
    isShowField : any | undefined,
    mapField : any | undefined,
    setIsMapField : any | undefined,
    isFieldsName : any | undefined,
    setIsFieldsName : any | undefined,
    handleupdatelocationfield : any | undefined,
    locationaddress : any | undefined,
    setlocationaddress : any | undefined,
    siteField : any | undefined,
    setSiteField : any | undefined,
    fieldName : any | undefined,
    setIsFieldName : any | undefined,
    isShowEdit : any | undefined,
    showAddEventsModal : any | undefined,

}

const AVCreateEventModal : FC<Props> = ( { Entity, setShowAddEventsModal, repeatWeek, setRepeatWeek, isGame, typeevent, isPractice, setSiteField, setlocationaddress, locationaddress, isShowEdit, showAddEventsModal } ) =>
{

    const navigate = useNavigate();
    const dimensions = Dimensions.get( 'window' );
    const isScreen = dimensions?.width <= 1000 || false;
    const isMobile = dimensions?.width <= 600 || false;
    const currentUser:any = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const dispatch = useDispatch();
    const CurrentDateTime = new Date();
    CurrentDateTime.setMinutes( 0 );
    CurrentDateTime.setSeconds( 0 );
    CurrentDateTime.setMilliseconds( 0 );
    CurrentDateTime.setHours( CurrentDateTime.getHours() + 1 )
    const year = CurrentDateTime.getFullYear();
    const month = ( CurrentDateTime.getMonth() + 1 ).toString().padStart( 2, '0' );
    const day = CurrentDateTime.getDate().toString().padStart( 2, '0' );
    const TeamArrivalDateTime = new Date();
    TeamArrivalDateTime.setMinutes( 0 );
    TeamArrivalDateTime.setSeconds( 0 );
    TeamArrivalDateTime.setMilliseconds( 0 );
    const timeOnly = TeamArrivalDateTime.toLocaleTimeString( [], { hour: 'numeric', minute: 'numeric', hour12: true } );
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const DefaultEventDate = `${ year }-${ month }-${ day }`;
    const DefaultEventTime = new Date().toLocaleTimeString( [], { hour: 'numeric', minute: 'numeric', hour12: true } );
    let reoccurance = useState( [ false, false, false, false, false, false, false ] );
    const [ eventLocation, setEventLocation ] = useState<any>( '' );
    const [ eventTitle, setEventTitle ] = useState( '' );
    const [ eventStartDate, setEventStartDate ] = useState<any>( DefaultEventDate ? DefaultEventDate : '' );
    const [ eventStartTime, setEventStartTime ] = useState<any>( CurrentDateTime ? CurrentDateTime : '' );
    const [ eventEndDate, setEventEndDate ] = useState<any>( DefaultEventDate );
    const [ eventTeamArrivalDate, setTeamArrivalDate ] = useState<any>();
    const [ teamArrivalDate, setArrivalDate ] = useState<any>();
    const [ teamArrivalTime, setArrivalTime ] = useState<any>();
    const [ addLink, setAddLink ] = useState( false );
    const [ isModalLoader, setModalLoader ] = useState( false );
    const [ eventsList, setEventsList ] = React.useState<AVI.IEvent[]>( [] );
    const [ url, setUrl ] = useState('');
  let endDate2 = new Date(CurrentDateTime);
  endDate2.setHours(endDate2.getHours() + 1);
  const [ eventEndTime, setEventEndTime ] = useState<any>(endDate2 ? endDate2 : '' );
    const [ repeat, setRepeat ] = useState( '' );
    const [ isWeek, setIsWeek ] = useState( false );
    const [ teamArrival, setTeamArrival ] = useState( "" );
    const [ description, setDescription ] = useState<any>( '' );
    const [ profile, setProfile ] = useState<any>( {
        background: null,
        pic: null,
        thumb: null,
        gallery: null,
        previousPic: null,
        text: null,
    } );
    const [ refreshKey, setRefreshKey ] = useState( 0 ); // Key prop for forcing re-render
    const [ refreshTimeKey, setRefreshTimeKey ] = useState( 0 ); // Key prop for forcing re-render
    const [ error, setError ] = useState( '' )
    const [ isValidateDate, setIsValidDate ] = useState( true )
    const [ isValidatEndDate, setIsValidEndDate ] = useState(true)
    const [ isValidStartTime, setIsValidStartTime ] = useState(true)
    const [ isValidEndTime, setIsValidEndTime ] = useState(true)
    const [ mapField, setIsMapField ] = useState( Entity?.location?.field?.name ? Entity?.location?.field?.name : '' );
    const [ locationEntity, setLocationEntity ] = useState<any>( '' );
    const [ isFieldsName, setIsFieldsName ] = useState( '' );
    const [ isCreateevent, setCreateevent ] = useState( true )
    const teamarivaldefaultdate = new Date( 0, 0, 0, 12, 0 );
    const [ addLinkPopup, setAddLinkPopup ] = useState( false );
    const [ linkList, setLinksList ] = useState<any>( [] )
  const adjustedEventTime = CurrentDateTime.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
  const [ startingtime, setstartingtime ] = useState(adjustedEventTime);
  let newEndingTime = endDate2.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
  const [ endingtime, setendingtime ] = useState(newEndingTime );
  const [ isValidUrl, setIsValidUrl ] = useState( true ); // Initialize as true assuming the URL is initially valid
    const [ triggerEndDate, setTriggerEndDate ] = useState( false );
    const [ triggerStartTime, setTriggerStartTime ] = useState( false );


    //Title
    const handleEventTitle = ( e ) =>
    {
        e.preventDefault();
        setEventTitle( e.target.value );
    };
    //StartDate
    const handleEventStartDate = ( e ) =>
    {
      if (e != null) {
        const inputDate = new Date(e);

        // Check if the entered date is a valid date
        if (isNaN(inputDate.getTime())) {
          //setError( "Invalid date entered" );
          setIsValidDate(false);
          setEventStartDate("");
          // You can set an error state or display an error message here
          return;
        }

        const date = new Date(e);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        const formattedDate = date.toISOString().split('T')[ 0 ];
        setEventStartDate(formattedDate);
        setError('');
        setIsValidDate(true)
        if (formattedDate > eventEndDate) {
            setEventEndDate( formattedDate );
            const timer = setTimeout( () =>
            {
                setTriggerEndDate( !triggerEndDate );
            }, 5000 ); 
        }
      }
    };
    //EndDate
    const handleEventEndDate = ( e ) =>
    {
      if (e != null) {
        const inputDate = new Date(e);

        // Check if the entered date is a valid date
        if (isNaN(inputDate.getTime())) {
          // setError( "Invalid date entered" );
          setIsValidEndDate(false);
          setEventEndDate("");
          // You can set an error state or display an error message here
          return;
        }
        const date : any = new Date(e);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        const formattedDate = date.toISOString().split('T')[ 0 ];
        setEventEndDate(formattedDate);
        setError('');
        setIsValidEndDate(true)
      }
      };

    //TeamDate
    const handleEventTeamArrival = ( e ) =>
    {
        if ( e !== null )
        {
            const date : any = new Date( e );
            date.setMinutes( date.getMinutes() - date.getTimezoneOffset() );
            const formattedDate = date.toISOString().split( 'T' )[ 0 ];
          setArrivalDate( formattedDate );
        }
        else
        {
          setArrivalDate('');
        }
        
    };

    const correctTimeFormat = ( timeString : any ) =>
    {
        const time = timeString.toLowerCase();
        const timeRegex = /^(\d{1,2}):(\d{2}) ([ap]m)$/;
        const match = time.match( timeRegex );
        if ( match )
        {
            let hour = parseInt( match[ 1 ], 10 );
            const minute = match[ 2 ];
            const period = match[ 3 ].toUpperCase(); // Convert "am/pm" to uppercase      
            if ( period === 'PM' && hour !== 12 )
            {
                hour += 12;
            } else if ( period === 'AM' && hour === 12 )
            {
                hour = 0;
            }
            const formattedTime = `${ hour.toString().padStart( 2, '0' ) }:${ minute }`;
            return formattedTime;
        }
        return timeString;
    };
    //StartTime
    const handleEventStartTime = async ( e ) =>
    {
        handlechange( e )
        const myDate:any = new Date( e );
        if ( myDate.toString() === "Invalid Date" || isNaN( myDate ) || e == null  )
        {
          setIsValidStartTime(false);
          setEventStartTime("");
            return;
        }
        const timeString = myDate.toLocaleTimeString( [], { hour: '2-digit', minute: '2-digit' } );
        let val = await correctTimeFormat( timeString );
        const [ hours, minutes ] = val.split( ':' ).map( Number );
        let newdate = new Date( eventStartDate );
        newdate.setHours( hours );
        newdate.setMinutes( minutes );
        setEventStartTime(newdate);
        setIsValidStartTime(true);
        if (((eventStartDate === eventEndDate) || (typeevent === 'Meeting' || typeevent === 'Practice')) )
        {
            handleChangeEnd( hours, minutes, newdate, eventStartDate )
        }
    };
    const handleChangeEnd = ( hours, minutes, newdate, eventStartDate ) =>
    {
        const newdatePlusOneHourClone = new Date( eventStartDate );
        newdatePlusOneHourClone.setHours( hours );
        newdatePlusOneHourClone.setMinutes( minutes );
        newdatePlusOneHourClone.setHours( newdatePlusOneHourClone.getHours() + 1 );
        setEventEndTime( newdatePlusOneHourClone );
        if ( newdate >= new Date( eventEndTime ) )
        {
            setTriggerStartTime( !triggerStartTime );
        }

        handlechangeend( newdatePlusOneHourClone );
    }
    const handlechange = ( e ) =>
    {
        const myDate:any = new Date( e );
        if ( myDate.toString() === "Invalid Date" || isNaN( myDate ) || e == null )
        {
          setIsValidStartTime(false);
          setstartingtime("");
            return;
        }
      const currentHour = myDate.getHours();
      const newDate = new Date(myDate);
      newDate.setHours(currentHour);
      const timeString = newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
       setstartingtime( timeString );
    }
    //EndTime
    const handleEventEndTime = async ( e ) =>
    {
      const myDate : any = new Date(e);
      if (myDate.toString() === "Invalid Date" || isNaN(myDate) || e == null) {
        setIsValidEndTime(false);
        setEventEndTime("");
        setendingtime("");
        return;
      }
        handlechangeend( e )
        const timeString = myDate.toLocaleTimeString( [], { hour: '2-digit', minute: '2-digit' } );
        let val = await correctTimeFormat( timeString );
        const [ hours, minutes ] = val.split( ':' ).map( Number );
        let newdate = new Date( eventEndDate );
        newdate.setHours( hours );
        newdate.setMinutes( minutes );
        setEventEndTime( newdate );
    };

    const handlechangeend = ( e ) =>
    {
      const myDate:any = new Date(e);
      if (myDate.toString() === "Invalid Date" || isNaN(myDate) || e == null) {
        setIsValidEndTime(false);
        setEventEndTime("");
        setendingtime("");
        return;
      }
        setIsValidEndTime(true);
        const currentHour = myDate.getHours();
        const newDate = new Date( myDate );
        newDate.setHours( currentHour);
        const timeString = newDate.toLocaleTimeString( [], { hour: '2-digit', minute: '2-digit' } );
        setendingtime( timeString );
    }
    //Team arival time
    const handleTeamArrivalTime = ( e ) =>
    {
        if ( e !== null )
        {
            const myDate = new Date( e );
            const timeString = myDate.toLocaleTimeString( [], { hour: '2-digit', minute: '2-digit' } );
            setTeamArrival( timeString )
        }
        else
        {
            setTeamArrival( '' );
        }
    };

    const customS = {
        maxHeight: '53px', // Set your desired height here
    };
    //Description
    const handleIntroText = ( e ) =>
    {
        setDescription( e.target.value );
        setProfile( ( profile ) => ( { ...profile, text: e.target.value } ) );
    };

    //Url
    const handleEventUrl = ( e ) =>
    {
        e.preventDefault();
        const inputUrl = e.target.value;
        const isValidLink = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;
        setIsValidUrl( isValidLink.test( inputUrl ) );
        setUrl( inputUrl ); // Assuming you're updating the URL state here
    };

    //AddEVENT Function
   const handleAddEvents = async () =>
    {

        const isValidLink = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;
        if ( url )
        {
            if ( !isValidLink?.test( url ) )
            {
                let clientError = { message: 'Invalid link format.' }
                CustomToast( { clientError } ); // Display error message using CustomToast
                setModalLoader( false );
                return; // Exit the function early if the link format is invalid
            }
        }

        if ( eventStartDate < DefaultEventDate || eventEndDate < DefaultEventDate )
        {
            let clientError = { message: Loc.currentLang.menu.invalidDate }
            CustomToast( { clientError } );
            return;
        }
        if ( eventStartDate > eventEndDate || eventEndDate < eventStartDate )
        {
            let clientError = { message: Loc.currentLang.menu.invalidDate }
            CustomToast( { clientError } );
            return;
        }

        //Time condtion starts
     if (eventStartDate === eventEndDate && eventStartDate === DefaultEventDate )
        {
            const currentDateTimes = new Date();
            const eventStartTimeOnly = Moment( eventStartTime ).format( 'HH:mm:ss' );
            const eventStartDateTimes = eventStartTime;
            if ( eventStartDateTimes < currentDateTimes )
            {
                const clientError = { message: Loc.currentLang.menu.invalidStartTime };
                CustomToast( { clientError } );
                return;
            }
         if ( typeevent === 'Meeting' || typeevent === 'Practice' )
         {
             const hasTrueValue = reoccurance[ 0 ].filter( value => value === true ).length > 0;
         }
            // Check if event end date is in the past
            const eventEndTimeOnly = Moment( eventEndTime ).format( 'HH:mm:ss' );
            const eventEndDateTimes = eventEndTime;
            if ( eventEndDateTimes < currentDateTimes )
            {
                const clientError = { message: Loc.currentLang.menu.invalidEndTime };
                CustomToast( { clientError } );
                return;
            }


            // Check if event start time is after event end time
            if ( eventStartTimeOnly > eventEndTimeOnly )
            {
                const clientError = { message: Loc.currentLang.menu.startTimeAfterEnd };
                CustomToast( { clientError } );
                return;
            }

            if ( eventStartTimeOnly === eventEndTimeOnly )
            {
                const clientError = {
                    message:
                        Loc.currentLang.menu.sameStartAndEndTime
                };
                CustomToast( { clientError } );
                return;
            }

            if ( isGame )
            {
              if (teamArrivalDate > eventStartDate || teamArrivalDate < eventStartDate) {
                const clientError = {
                  message: "Team arrival date must be same as event start date"
                };
                CustomToast({ clientError });
                return;
              }

              if (teamArrival > startingtime)
                {
                    const clientError = {
                        message:"Team arrival time should before the start time"
                    };
                    CustomToast( { clientError } );
                    return;
                }
            }

        }

        if ( eventStartDate === DefaultEventDate && eventEndDate > eventStartDate )
        {
            const currentDateTimes = new Date();
            const eventStartDateTimes = eventStartTime;
            if ( eventStartDateTimes < currentDateTimes )
            {
                const clientError = { message: Loc.currentLang.menu.startInThePast };
                CustomToast( { clientError } );
                return;
            }
     }

       if ( isWeek )
       {
           const hasdaysselected = reoccurance[0].some( value => value === true );
           if ( !hasdaysselected )
           {
               const clientError = {
                   message: "Select minimum one day for the repeat event."
               };
               CustomToast( { clientError } );
               return;
           }
       }

     if (eventStartDate === eventEndDate && eventStartTime > eventEndTime ) {
       const clientError = {
         message: "Invalid start time. Event cannot start after the end time."};
         CustomToast({ clientError });
         return;
       }

        if ( isGame )
        {
          if (teamArrivalDate === eventStartDate )
            {
                if ( teamArrival >= startingtime )
                {
                    const clientError = {
                        message: "Team arrival time must be before the start time"
                    };
                    CustomToast( { clientError } );
                    return;
                }
            }
        }
     let concatfieldmap = {
       field: isFieldsName ? isFieldsName : {},
       siteMap: locationEntity?.siteMap ?? {}
     };



        let locationoffield = {
            ...locationaddress,
            ...concatfieldmap,
        };

        setModalLoader( true );
        var eventStartDateTime = Moment( eventStartDate + ' ' + startingtime ).toDate();
     var eventEndDateTime;
       if ( typeevent === 'Meeting' || typeevent === 'Practice') {
       eventEndDateTime = Moment(eventStartDate + ' ' + endingtime).toDate();
     }
     else
     {
       eventEndDateTime = Moment(eventEndDate + ' ' + endingtime).toDate();
     }
     var teamArivalDateTime = Moment(teamArrivalDate + ' ' + teamArrival ).toDate();
        var reoccurEndDateTime = Moment( eventEndDate + ' ' + endingtime ).toDate();
        let currentDate = new Date();
        //let currentDateTime = new Date( ( Moment( currentDate ).format( 'L' ) + ' ' + Moment( currentDate ).format( 'LTS' ) ) )
        //if ( eventStartDateTime < currentDateTime )
        //{
        //    let clientError = { message: eventStartDate == Moment( new Date() ).format( 'L' ) ? "invalid start time": "invalid start date" }
        //    CustomToast( { clientError } );
        //    setModalLoader( false );
        //    return;
        //}  
        var event : AVI.IEvent = {
            type: 'Event',
            name: eventTitle,
            eventType: typeevent,
            start: eventStartDateTime,
            end: eventEndDateTime,
            profile: profile,
            playerStart: typeevent == 'Game' ? teamArivalDateTime : null,
            parent: AV.AVEntity.getFk( Entity ),
            reoccurance: isWeek
                ? { type: 'Weekly', days: reoccurance[ 0 ], endDate: reoccurEndDateTime }
                : undefined,
            location: addLink ? null : locationoffield,
            locURL: url,
            links: linkList,
        };

        event = await AV.AVEntity.save( event );
        if ( event )
        {
          let eventList = eventsList;
          event.status = 'RSVP';
          if (event.reoccurance)
          {
            const targetFK:any = {
              Id: Entity.id,
              Pk: Entity.id?.substr(-4),
              Region: Entity.region,
              Type: Entity.type,
            };
            const response : any = await AV.AVEntity.get(targetFK);
            if (currentUser?.id == event?.parent?.id) {
              currentUser.ownedEvents = [];
              currentUser.ownedEvents = response.ownedEvents;
              currentUser.events = []
                currentUser.events = response.events;
                dispatch( setUser( currentUser ) );
            }
            if (Entity.type == 'Team')
              eventList = response.ownedEvents;
            eventList = response.events;
          }

          else
          {
            currentUser?.events?.unshift(event);
            if (currentUser?.id == event?.parent?.id) {
              currentUser.ownedEvents?.unshift(event);
            }
            eventList = eventsList;
              eventList.unshift( event );
              dispatch( setUser( currentUser ) );
          }
            
          setEventsList( eventList );
          entitydatalist.push(event);
          dispatch(setUser(currentUser));

          let index = entitydatalist?.findIndex(x => x?.id === currentUser?.id);
          if (index != -1) {
            entitydatalist[ index ] = currentUser;
            dispatch(setEntitydata(entitydatalist));
          }
        }
        setShowAddEventsModal( false );
        setModalLoader( false );
        setlocationaddress( [] );
        navigate( `/Events/${ event?.id }/${ event?.region }`, { state: { team: event, newEntity: true }, } );
    };

    const handleupdatelocationfield = ( e, siteField ) =>
    {
        setIsMapField( e?.name )
        setIsFieldsName( siteField?.find( ( x : any ) => x?.name == e ) );
    };

    const handlePractice = ( event ) =>
    {
        const selectedValue = event.target.value;
        setRepeat( selectedValue );
        setRepeatWeek( selectedValue );
        if ( selectedValue === 'Repeat' )
        {
            setIsWeek( true );
        } else
        {
            setIsWeek( false );
        }
    };

    const handleReoccurance = async ( index ) =>
    {
        reoccurance[ 0 ][ index ] === false
            ? ( reoccurance[ 0 ][ index ] = true )
            : ( reoccurance[ 0 ][ index ] = false );
    };

    React.useEffect( () =>
    {
        setRepeat( Loc.currentLang.menu.doesNotRepeat );
        setlocationaddress( [] );
        setEventLocation( '' );
        setLocationEntity( '' )
    }, [ showAddEventsModal ] )
    React.useEffect( () =>
    {
        setRefreshKey( prevKey => prevKey + 1 );
    }, [ triggerEndDate ] );

    React.useEffect( () =>
    {
        setRefreshTimeKey( prevKey => prevKey + 1 );
    }, [ triggerStartTime ] );

    return (
        <Box
            position='fixed'
            bgColor={ 'rgba(0, 0, 0, 0.25)' }
            bottom={ 0 }
            right={ 0 }
            top={ 0 }
            left={ 0 }
            height={ '100%' }
            width={ '100%' }
        >
            <div
                style={ {
                    position: 'fixed',
                    top: '56%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: '#ffffff',
                    boxShadow:
                        '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                    width: isMobile ? '88%' : '580px',
                    borderRadius: '8px',
                    height: '80%',
                    overflow: 'auto',
                } }
            >
                <VStack width={ '100%' } marginBottom={ '30px' }>
                    <HStack
                        alignItems={ 'center' }
                        borderBottomColor={ 'AVColor.grey' }
                        width={ 'auto' }
                        bgColor={ 'AVColor.white' }
                        marginX={ '24px' }
                        marginTop={ '24px' }
                        justifyContent={ 'space-between' }
                    >

                        {/* <Text fontSize={30} >{"Create " + typeevent + " Event"}</Text> */ }

                        <Text fontSize={ 30 }>
                            { Loc.currentLang.menu.create + " " +
                                (
                                    typeevent === 'Practice' ? Loc.currentLang.menu.practice :
                                        typeevent === 'Game' ? Loc.currentLang.menu.gameText :
                                            typeevent === 'Social' ? Loc.currentLang.menu.socialText :
                                                typeevent === 'Meeting' ? Loc.currentLang.menu.meeting :
                                                    typeevent
                                ) + " " + Loc.currentLang.menu.event }
                        </Text>

                        <Pressable
                            onPress={ () => { setShowAddEventsModal( false ); setlocationaddress( [] ); setEventLocation( '' ); } }
                            _hover={ { bgColor: 'AVColor.grey', borderRadius: 'sm' } }
                            rounded={ 'full' }
                            borderBottomColor={ 'AVColor.grey' }
                            px={ -1 }
                        >
                            <CrossIcon size='40px' color='#000000' alignSelf={ 'center' } />
                        </Pressable>
                    </HStack>
                    <VStack width={ 'auto' } marginX={ '24px' } space={ '18px' } marginBottom={ '60px' } marginTop={ repeatWeek ? '24px' : '' }>
                        <VStack width={ '100%' }>
                            { repeatWeek && ( <Box width={ '100%' }>
                                <FormControl fullWidth>
                                    <InputLabel id='demo-simple-select-label'>
                                        { Loc.currentLang.menu.repeatReq }
                                    </InputLabel>
                                    <Select value={ repeat } label='Repeat' onChange={ handlePractice }>
                                        <MenuItem value='Does not Repeat'>
                                            { Loc.currentLang.menu.doesNotRepeat }
                                        </MenuItem>
                                        <MenuItem value='Repeat'>{ Loc.currentLang.menu.repeat }</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box> ) }
                            { repeatWeek && isWeek && ( <VStack paddingY={ 3 } zIndex={ -2 } width={ '100%' } >
                                <HStack space={ '16px' } flexWrap={ 'wrap' }>
                                    <Checkbox value='false' onChange={ () => handleReoccurance( 1 ) }>
                                        Mon
                                    </Checkbox>
                                    <Checkbox value='false' onChange={ () => handleReoccurance( 2 ) }>
                                        Tues
                                    </Checkbox>
                                    <Checkbox value='false' onChange={ () => handleReoccurance( 3 ) }>
                                        Wed
                                    </Checkbox>
                                    <Checkbox value='false' onChange={ () => handleReoccurance( 4 ) }>
                                        Thurs
                                    </Checkbox>
                                    <Checkbox value='false' onChange={ () => handleReoccurance( 5 ) }>
                                        Fri
                                    </Checkbox>
                                    <Checkbox
                                        value='false'
                                        onChange={ () => handleReoccurance( 6 ) }
                                    >
                                        Sat
                                    </Checkbox>
                                    <Checkbox
                                        value='false'
                                        onChange={ () => handleReoccurance( 0 ) }
                                    >
                                        Sun
                                    </Checkbox>
                                </HStack>
                            </VStack> ) }
                        </VStack>
                        {/*TitleInput*/ }
                        <Box width={ '100%' } >
                            <AVInputtext
                                type={ 'text' }
                                label={ Loc.currentLang.menu.eventTitle }
                                value={ eventTitle }
                                onChange={ handleEventTitle }
                                maxLength={ 50 }
                                defaultValue={ undefined }
                                width={ undefined }
                                height={ undefined }
                                minRows={ undefined }
                                multiline={ undefined }
                                size={ undefined }
                                placeholder={ undefined } />
                        </Box>
                        {/*Start and End Date*/ }
                        <HStack space={ '2%' } flexWrap={ 'wrap' } >
                            <Box width={ isScreen ? '100%' : '48.5%' } marginBottom={ isScreen ? '15px' : '' }>
                                <AVDatePicker
                                    key={ `end-date-picker-${ refreshKey }` }
                                    label={repeat == 'Repeat' && (typeevent === 'Meeting' || typeevent === 'Practice') ? "Reoccurance start date *" :  Loc.currentLang.menu.startDate }
                                    onChange={ handleEventStartDate }
                                    defaultValue={ dayjs( eventStartDate ) }
                                />
                            </Box>
                            <Box width={ isScreen ? '100%' : '48.5%' }>
                                <AVDatePicker
                                    key={ `end-date-picker-${ refreshKey }` }
                                    label={ repeat == 'Repeat' && (typeevent === 'Meeting' || typeevent === 'Practice') ? "Reoccurance end date *": Loc.currentLang.menu.endDate }
                                    onChange={ handleEventEndDate }
                                    defaultValue={ dayjs( eventEndDate ) }
                                />
                            </Box>
                        </HStack>
                        {/*{ error && <HStack>
                            <Text color={ 'red.500' }>{ error }</Text>
                        </HStack> }*/}
                        {/*TeamArival*/ }
                        { isGame && (
                            <Box width={ '100%' }>
                                <AVTimePicker
                                    defaultValue={ null }
                                    label={ Loc.currentLang.menu.teamArivalTime }
                                    onChange={ handleTeamArrivalTime }
                                />

                            </Box> ) }
                        { isGame && (
                            <Box width={ '100%' }>
                                <AVDatePicker
                                    label={ Loc.currentLang.menu.teamArrivalDate }
                                    onChange={ handleEventTeamArrival }
                                    defaultValue={ null }
                                />
                            </Box> ) }
                        {/*TimeInput*/ }
                        <HStack space={ '2%' } flexWrap={ 'wrap' } >
                            <Box width={ isScreen ? '100%' : '48.5%' } marginBottom={ isScreen ? '15px' : '' }>
                                <AVTimePicker
                                    key={ `start-time-picker-${ refreshTimeKey }` }
                                    defaultValue={ dayjs( eventStartTime ) }
                                    label={ Loc.currentLang.menu.startTime }
                                    onChange={ handleEventStartTime }
                                />
                            </Box>
                            <Box width={ isScreen ? '100%' : '48.5%' }>
                                <AVTimePicker
                                    key={ `start-time-picker-${ refreshTimeKey }` }
                                    defaultValue={ dayjs( eventEndTime ) }
                                    label={ Loc.currentLang.menu.endTime }
                                    onChange={ handleEventEndTime }
                                />
                            </Box>
                        </HStack>
                        <Box width={ '100%' } >
                            <AVInputtext
                                type={ 'text' }
                                minRows='5'
                                size='medium'
                                multiline={ true }
                                maxLength={ undefined }
                                onChange={ handleIntroText }
                                defaultValue={ undefined }
                                width={ undefined } value={ description }
                                height={ '60px' }
                                label={ Loc.currentLang.placeholderText.personalDescription }
                                placeholder={ undefined } />

                        </Box>
                        {/*CheckBox*/ }
                        <Box>
                            <Stack
                                direction={ {
                                    base: 'row',
                                    md: 'row',
                                } }
                                space={ '35px' }
                                alignItems='flex-start'
                                zIndex={ -2 }
                            >
                                <Checkbox
                                    size='sm'
                                    zIndex={ -2 }
                                    isChecked={ addLink }
                                    onChange={ () => { setAddLink( !addLink ); setUrl( '' ); } }
                                    value={ '' }
                                >
                                    { Loc.currentLang.menu.onlineEvent }
                                </Checkbox>
                            </Stack>
                        </Box>
                        {/*UrlInput*/ }
                        { addLink && (
                            <Box width={ '100%' }>
                                <AVInputtext
                                    type={ 'text' }
                                    label="Event Url *"
                                    onChange={ handleEventUrl }
                                    value={ url }
                                    maxLength={ 500 }
                                    defaultValue={ undefined }
                                    width={ undefined }
                                    height={ undefined }
                                    minRows={ undefined }
                                    multiline={ undefined }
                                    size={ undefined }
                                    placeholder={ undefined } />
                            </Box>
                        ) }
                        { (addLink && !isValidUrl)&& (
                            <View top='-8'>
                                <Text pt="3" bold>
                                    Example: http://www.example.com or http://example.com or https://example.com
                                </Text>
                            </View>
                        ) }

                        
                        {/*LocationInput*/ }
                        { !addLink && (
                            <AVLocationBox isGame={ isGame } isPractice={ isPractice } setSiteField={ setSiteField } locationaddress={ locationaddress } setlocationaddress={ setlocationaddress } isCreate={ undefined } handleupdatelocationfield={ handleupdatelocationfield } setEventLocation={ setEventLocation } eventLocation={ eventLocation } isShowEdit={ isShowEdit } Entity={ Entity } setLocationEntity={ setLocationEntity } locationEntity={ locationEntity } mapField={ undefined } setIsMapField={ undefined } isCreateevent={ isCreateevent } />
                        ) }
                        {/*Links*/ }
                        <Pressable
                            width={ '100%' }
                            zIndex={ -3 }
                            onPress={ () => { setAddLinkPopup( true ) } }
                        >
                            <HStack>
                                <Text color={ '#00A7BC' } fontWeight={ 500 }>+</Text>
                                <Text color={ '#00A7BC' } fontWeight={ 500 } >
                                    { Loc.currentLang.menu.addLinks }</Text>
                            </HStack>
                        </Pressable>
                        { addLinkPopup && ( <AddandEditLinks setAddLinkPopup={ setAddLinkPopup } setLinksList={ setLinksList } linkList={ linkList } isCreateevent={ isCreateevent } /> ) }
                        {/*Create and cancel button*/ }
                        <HStack width={ '100%' } justifyContent={ 'center' } space={ 3 } position={ 'absolute' } bottom={ '-60px' } zIndex={ -1 } >
                            <Button
                                width={ isMobile || isScreen ? '40%' : '180px' }
                                height={ '40px' }
                                backgroundColor={ '#EEEEEE' }
                                rounded={ 'full' }
                                color={ 'rgba(0,0,0,0.5)' }
                                onPress={ () => { setShowAddEventsModal( false ); setlocationaddress( [] ); setEventLocation( '' ); } }
                            >
                                <Text fontSize={ isMobile || isScreen ? '14px' : '18px' } color='rgba(0,0,0,0.5)'>{ Loc.currentLang.menu.cancel }</Text>
                            </Button>
                <Button
                  isDisabled={ !addLink ? (eventTitle?.trim()?.length === 0 || dayjs(eventEndTime).format() === 'Invalid Date' || locationaddress?.id?.trim()?.length === 0 || locationaddress?.id?.trim()?.length === undefined || !isValidateDate || !isValidatEndDate || !isValidStartTime || !isValidEndTime) : (addLink ? (eventTitle?.trim()?.length === 0 || url?.trim()?.length === 0) : (!isValidateDate || !isValidatEndDate || !isValidStartTime || !isValidEndTime)) }
                                width={ isMobile || isScreen ? '40%' : '180px' }
                                backgroundColor={ '#32AE78' }
                                height={ '40px' }
                                rounded={ 'full' }
                                isLoading={ isModalLoader }
                                onPress={ () => handleAddEvents() }
                            >
                                <Text fontSize={ isMobile || isScreen ? '14px' : '18px' } color='white'>{ Loc.currentLang.menu.create }</Text>
                            </Button>
                        </HStack>
                    </VStack>
                </VStack>
            </div>
        </Box>
    )
}

export default AVCreateEventModal;